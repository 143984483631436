.dropdown-heading-dropdown-arrow {
  display: none!important;
}
.dropdown-heading {
  border-color: rgb(217, 217, 217) rgb(204, 204, 204) rgb(179, 179, 179)!important;
  border-width: 1px!important;
  border-style: solid!important;
  border-image: initial!important;
};
.select-list li {
  cursor: pointer!important;
  span{
    cursor: pointer!important;
  }
  input{
    cursor: pointer!important
  }
}
label.select-item {
  padding: 0 10px!important;
  span{
    span{
      padding: 0 10px 9px 10px!important;
    }
  }
}
.custom-multiselect {
  position: relative;
  height: 46px;
  .dropdown-heading-dropdown-arrow span {
    border-color: rgb(153, 153, 153) transparent transparent!important;
  }
  .dropdown-heading-value {
    padding-top: 5px;
  }
  .dropdown-heading {
    height: 46px!important;
  }
  .dropdown {
    width: 100%;
  }
  .rw-widget-input{
    padding-bottom: 7px;
    padding-left: 10px;
  };
}
.multiselect-w-100 {
  max-width: inherit;
}