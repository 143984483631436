/* TariffCalculatedContainer */
@media screen and (min-width: 992px) and (max-width: 1630px){
  .tariff-responsive-right {
    text-align: right;
    .white-bg {
      background-color: #fff;
    }
  }
}
@media screen and (max-width: 991px) {
  .tariff-content {
    text-align: center;
    .labelling-file-wrapper {
      margin: 0 auto;
    }
  }
}
.tariff-content .labelling-file-wrapper {
  max-width: 180px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.tariff-overview-details-btn-wrapper {
  margin: 0 auto;
  position: relative;
}
.tariff-overview-details-btn {
  position: absolute;
  left: -103px;
}
.calculated-tariff .custom-border-bottom {
  margin-bottom: 0;
}
.tariff-header-boxes, .timeline-header-boxes{
  position: absolute;
  top: -10px;
  right: 30px;
}
.tariff-subtitle {
  text-transform: inherit;
  font-size: 16px;
  margin-bottom: 0;
}
.tariff-subtitle-data {
  font-family: RegularFont, sans-serif;
  font-size: 22px;
}
.tariff-body {
  min-height: 140px;
  .labelling-file-wrapper img {
    max-height: 130px;
  }
}
.tariff-footer {
  padding: 15px 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
}
.panel {
  height: 0;
  background: #fff;
  transition-duration: 0.5s;
  overflow: hidden;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  &.show {
    height: auto;
  }
}
.nav-tabs {
  border-bottom: none;
  font-size: 20px;
  background-color: #F6F9FC;
  .nav-link {
    padding: 0.75rem;
    border-radius: 0;
    border-bottom: 1px solid #E9ECEF;
    .active {
      transition-duration: 0.5s;
      background-color: #fff;
    }
    &:hover:not(.active) {
      border-color: #E9ECEF;
    }
  }
  &.round  {
    li {
      &:first-child {
        .nav-link {
          border-top-left-radius: 0.375rem;
        }
      }
      &:last-child {
        .nav-link {
          border-top-right-radius: 0.375rem;
        }
      }
    }
  }
  .nav-item {
    width: 20%;
    text-align: center;
    cursor: pointer;
    color: var(--grey-font-color);
    .active {
      color: var(--primary-color);
      font-family: BoldFont, serif!important;
      background-color: #fff!important;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
.two-tabs {
  .nav-item{
    width: 50%;
  }
}
.three-tabs {
  .nav-item{
    width: 33.3%;
  }
}
.four-tabs {
  .nav-item{
    width: 25%;
  }
}
.five-tabs {
  .nav-item{
    width: 20%;
  }
}
@media screen and (max-width: 827px) {
  .five-tabs, .four-tabs, .three-tabs {
    .nav-item{
      width: 100%!important;
    }
  }
}
.tariff-price-details-row {
  margin: 10px 15px;
  padding-bottom: 17px;
  padding-top: 7px;
  .tariff-details-row-title{
    padding-left: 0;
  }
}
.tariff-details-row-title{
  font-family: SemiBoldFont, sans-serif;
}
.tariff-filter-btn {
  cursor: pointer;
  transition-duration: 0.3s;
  background-color: #E9ECEF;
  color: var(--grey-font-color);
  padding: .5rem;
  margin: 0 -1.5rem -1.5rem -1.5rem;
  text-align: center;
  border-radius:  0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
  border-top: 1px solid #E9ECEF;
  &:hover{
    background-color: #fff!important;
    transition-duration: 0.3s;
  }
}
.calculated-tariff button {
  height: 30px;
}
.price-detail-btn {
  border: 1px solid var(--grey-font-color)!important;
  box-shadow: none;
  color: var(--grey-font-color);
  background-color: #fff;
  font-weight: normal!important;
  font-family: SemiBoldFont, sans-serif!important;
}
.price-detail-btn:focus {
  box-shadow: none;
}
.price-detail-btn:hover {
  transform: inherit;
  box-shadow: none;
  color: var(--grey-font-color);
  background-color: #fff;
}
.price-detail-btn:disabled {
  border: 1px solid var(--grey-font-color);
  color: var(--grey-font-color);
}
.contract-header-detail-loading-first {
  position: absolute;
  right: 125px;
  top: -20px;
  border-radius: 5px;
}
.contract-header-detail-loading-second {
  position: absolute;
  right: 35px;
  top: -20px;
  border-radius: 5px;
}
.tariff-header-boxes {
  div{
    background-color: #fff;
    padding: 6px 15px;
    border-radius: 5px;
    border: 1px solid #E9ECEF;
    &.digital-contract {
      min-width: 146px;
    }
  }
}
.electricity-type {
  padding: 7px 15px;
  border-radius: 5px;
  color: #fff;
  &.green {
    background-color: #1AAE6F;
    border-color: #1AAE6F;
  }
  &.grey {
    background-color: var(--grey-font-color);
  }
}
.gray-hint-text{
  font-size: 14px!important;
  color: var(--grey-font-color)!important;
}
/* Pricedetails */
.tariff-information-table th, .tariff-information-table td {
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}
.tariff-information-table tr th {
  font-size: 16px;
  .fas {
    font-size: 10px!important;
  }
}
@media screen and (max-width: 731px) {
  .responsive-tabs {
    .tabs-separator{
      display: none;
    }
    .nav-item {
      width: 100%!important;
    }
  }
}
/* End Pricedetails */
@media screen and (max-width: 845px){
  .tariff-footer {
    .price-detail-btn {
      margin-bottom: 5px;
    }
    .btn-secondary{
      margin-left: 0!important;
    }
  }
}
@media screen and (max-width: 417px){
  .tariff-footer {
    display: block;
    .comparision-checkbox-wrapper {
      margin-bottom: 5px;
    }
  }
}
@media screen and (max-width: 488px){
  .tariff-responsive-btn {
    margin-bottom: 1rem;
  }
}
/* End TariffCalculatedContainer */
/* Collapsed Tariffs */
.collapsed-tariff-toggler {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px 30px;
  cursor: pointer;
  color: var(--grey-font-color);
  font-family: XBoldFont, sans-serif;
  transition-duration: 0.5s;
  &.open {
    margin-bottom: 1rem!important;
  }
}
/* End Collapsed Tariffs */