.eless-upload {
  position: absolute;
  left: 0;
  width: 100%;
  height: 70px;
  border: 2px dashed #c8c8c8;
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  text-align: center;
  font-size: 14px;
  font-family: SemiBoldFont, serif;
  margin-bottom: 0;
  .centered-upload-input-text {
    display: inline-block;
    margin-top: 22px;
  }
  .eless-link {
    text-decoration: underline;
    color: var(--secondary-color);
  }
}

.file-upload-eless {
  height: 70px!important;
  &.has-error .eless-upload {
    border-color: var(--error-color);
  }
  input {
    height: 70px!important;
  }
}

.eless-file-card {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 10px 40px 10px 10px;
  background-color: #F5F5F5;
  position: relative;
  .file-without-size {
    margin-bottom: 16px;
  }
  .file-name {
    font-family: SemiBoldFont, serif;
    line-height: 1.2;
  }
  .file-size {
    color: #b9b9b9;
    font-family: SemiBoldFont, serif;
    font-size: 14px;
    line-height: 1;
    padding-left: 20px;
  }
  .bin-icon-wrapper {
    cursor: pointer;
    color: #b9b9b9;
    position: absolute;
    bottom: 10px;
    right: 15px;
  }
  .dropdown-options-wrapper {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 20px;
    color: var(--grey-font-color);
  }
}

.straight-paperclip {
  font-size: 18px;
  transform: rotate(320deg);
  -moz-transform: rotate(320deg);
  -ms-transform: rotate(320deg);
  -o-transform: rotate(320deg);
  -webkit-transform: rotate(320deg);
}

.progress-bar-wrapper {
  .lottie-wrapper {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}