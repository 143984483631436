@media (min-width: 768px) and (max-width: 991.98px) {
    .ModalDialogWidth {
        max-width: 740px;
    }
}

@media (min-width: 992px) {
    .ModalDialogWidth {
        max-width: 850px;
    }

    @media (min-height: 600px) {
        .CardBody {
            overflow: auto;
            max-height: 500px;
        }
    }
}

.ModalHeader {
    display: block;
    padding: 2rem 1.25rem 0 1.25rem;
}

.ModalHeader hr {
    margin-top: 1rem;
    margin-bottom: 0;
}

.ModalHeaderComponents {
    display: flex;
}

.GroupHeader {
    border-radius: 0.375rem;
    background: #E9ECEF !important;
    margin-bottom: 12px;
}
.GroupHeader .buttons {
    text-align: right;
}
.GroupHeader .buttons div {
    display: inline-block;
}
.GroupHeader .buttons div:not(:last-child) {
    margin-right: 15px;
}
@media screen and (max-width: 594px){
    .GroupHeader .buttons {
        text-align: center;
    }
    .GroupHeader .buttons div {
        display: block;
    }
}

.AddAttributField {
    border-radius: 10px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
    cursor: pointer;
}

.AddAttributField:hover {
    box-shadow: 0 0 5px #ddd;
}

.AddAttributField h2 {
    margin-bottom: 10px;
}

.DisableAttributField {
    border-radius: 10px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
}

.InfoAttributField {
    padding: 20px 20px 35px 40px;
    text-align: justify;
}

.IconAddVerticalAlignment {
    padding: 0;
    position: relative;
}

.IconAddVerticalAlignment div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: xx-large;
}

.AttributAlreadyAdded {
    margin-top: 1rem;
    text-align: center;
}

.tree-attributes div:nth-of-type(2) .expansion-panel-header {
    border-top-left-radius: 0.375rem!important;
    border-top-right-radius: 0.375rem!important;
}
.tree-attributes div:last-of-type .expansion-panel-header {
    border-bottom-left-radius: 0.375rem!important;
    border-bottom-right-radius: 0.375rem!important;
}

.add-criteria-btn {
    margin-top: 25px;
}
.pricestrategy-info-icon-wrapper {
    font-size: 20px;
    position: absolute;
    right: -30px;
    top: -8px;
}

.ExpansionPanel .Header {
    width: 100%;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    padding: 1em 2em 1em 2em;
    cursor: pointer;
}

.ExpansionPanel .Content {
    width: 100%;
    padding: 0 1.65em;
}

.ExpansionPanel .RotateIcon {
    transition: all 0.2s ease-out;
    transform: rotate(-90deg);
    float: right;
}

.ExpansionPanel .PullRight {
    transition: all 0.2s ease-out;
    float: right;
}

@media screen and (max-width: 767px) {
    .sm-margin-bottom {
        margin-bottom: 15px;
    }
}

/*
BUTTON
 */
.add-btn {
    font-size: 24px;
    padding: 0 5px;
}


/*
CONDITION
 */

.Condition .Row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.Condition option {
    padding: 1.2rem;
}

.Condition .HideOption {
    visibility: collapse;
}

@media (max-width: 575.85px) {
    .Condition .InputFields {
        width: 100%;
        display: inline;

        flex-direction: row;
        flex-wrap: wrap;
    }

    .Condition .Textform {
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .Condition .InputFields {
        width: 76%;
        display: inline;

        flex-direction: row;
        flex-wrap: wrap;
    }

    .Condition .Textform {
        width: 75%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .Condition .InputFields {
        width: 83%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .Condition .InputFieldA {
        width: 32.5%;
    }

    .Condition .InputFieldB {
        width: 38%;
    }

    .Condition .InputFieldValues {
        width: 60%;
    }

    .Condition .Textform {
        width: 80%;
    }
}

@media (min-width: 992px) {
    .Condition .InputFields {
        width: 86.5%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .Condition .InputFieldA {
        width: 32.5%;
    }

    .Condition .InputFieldB {
        width: 24%;
    }

    .Condition .InputFieldValues {
        width: 24%;
    }

    .Condition .Textform {
        width: 85%;
    }
}

@media (min-width: 1500px) {
    .Condition .InputFields {
        width: 90%;
    }

    .Condition .Textform {
        width: 90%;
    }
}

.Condition .Btns {
    display: inline;
    margin-left: auto;
    margin-right: 0;
}

.Condition .Btn {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    color: inherit;
    outline: none;
    cursor: pointer;
    padding: 13px;
    margin: 2px;
    font-size: 20px;
}

.Condition .Btn:hover {
    box-shadow: 0 0 2px #ddd;
}

.Condition .Btn:active {
    box-shadow: 0 0 10px #ddd;
}

.Condition .BtnAdd {
    margin-left: 4px;
}

.Condition .BtnAddHidden {
    visibility: hidden;
}

.Condition .btnDeleteHide {
    visibility: hidden;
}

.Condition .Textform {
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;
}

.Condition .TextformField {
    font-size: 0.875rem;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    display: block;
    padding: 0.625rem 0.75rem;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    text-align: center;
}

.Condition .TextformField:active {
    border: 1px solid #8898aa;
    background-color: #fafafa;
}

.textform .branch{
    font-size: 0.875rem;
    display: block;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
    text-align: center;
    margin: 23px 0;
}

.textform .tree-buttons {
    font-size: 0.875rem;
    display: block;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
}

.tree-buttons {
    margin: 29px 0;
}
.tree-buttons button {
    height: 35px!important;
    padding: 0 12px!important;
}

.branch {
    margin: 12px 0;
    padding: 0.719rem 0;
}
