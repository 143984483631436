h3{
  font-size: 18px;
}
h4 {
  font-size: 11px;
  color: var(--dark-gray);
  font-weight: normal;
  text-transform: uppercase;
  padding-top:10px;
}
.green-font {
  color: green
}
.red-font {
  color: red
}
.eless-text-orange {
  color: var(--secondary-color);
  &.link:hover {
    color: var(--secondary-color);
  }
}
.input-handler-wrapper {
  position: relative;
}
.dropdown-menu {
  &.dropdown-menu-right {
    top: 6px!important;
  }
  &.email-dropdown-menu {
    top: -28px !important;
  }
}
.fontsize-100 {
  font-size: 100%!important;
}
.h2-description {
  font-size: 14px;
}
.align-self-center {
  align-self: center;
}
.vertical-top-wrapper {
  display : flex;
  justify-content: flex-start;
  height: 100%;
  flex-direction: column;
}
.center-text:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
body {
  color: var(--dark-font-color);
  font-family: RegularFont, serif;
  padding-right: 0!important;
}
.form-control, .form-control:active {
  color: var(--dark-font-color)!important;
}
.form-control:disabled {
  color: var(--disabled-color)!important;
}
//.form-control:focus {
//  border: 1px solid #dee2e6;
//}
.input-group {
  border: 1px solid transparent!important;
}
.form-font-size {
  font-size: 0.875rem;
}
.max-width-inherit {
  max-width: inherit!important;
}
.eless-bg-gradient {
  background: linear-gradient(87deg, #ffffff 0, var(--light-gray) 100%) !important;
}
.sweet-alert.second {
  top: 26%!important;
}
.eless-color {
  color: var(--dark-font-color);
}
.custom-file-input {
  z-index: 1!important;
}
.custom-file-input:focus ~ .custom-file-label, .custom-file-input:focus ~ .custom-file-label::after {
  border-color: var(--primary-color);
}
.file-upload-error-message {
  margin-top: -3px!important;
}
.alert-border .custom-file-input:focus ~ .custom-file-label,
.alert-border .custom-file-input:focus ~ .custom-file-label::after {
  border-color: #dee2e6;
}
.custom-file-label {
  z-index: 0!important;
  height: 46px;
  margin-bottom: 0;
}
.white-color {
  color: var(--light-font-color)!important;
}
.eless-fill {
  fill: var(--primary-color);
}
.reset-bold {
  font-family: inherit!important;
  font-weight: bold;
}
.disable-max-w {
  max-width: inherit!important;
}
.eless-bg {
  background: var(--primary-color);
}
.eless-bg-light {
  background: var(--primary-color)-light
}
img {
  max-width: 100%;
}
.form-field-height {
  height: 46px!important;
}
.input-height {
  height: 46px!important;
}
.table-input {
  max-height: 31px!important;
}
.eless-btn {
  height: 31px;
  padding: 0 30px;
}
@media screen and (max-width: 399px){
  .eless-btn {
    width: 100%;
  }
}
.static-warning-notification {
  background-color: #FFDBBE;
  padding: 8px 16px 10px;
  border-radius: 4px;
  margin-top: 15px;
  &.grey {
    background-color: var(--light-gray)!important;
  }
  &.more-padding {
    padding: 16px 26px;
  }
}
.secondary-text-color {
  color: var(--secondary-color);
}
.bg-color-transparent {
  background-color: transparent;
}
hr{
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.cursor-pointer {
  cursor: pointer;
}
.bg-info {
  background-color: var(--primary-color)!important;
}
.bg-eless-orange {
  background-color: var(--secondary-color);
}
.bg-edited {
  background-color: rgba(254, 130, 30, 0.15);
}
.table-title {
  font-size: 18px;
}
.main-card {
  border-radius: 6px;
  color: var(--light-font-color);
}
.height-50 {
  height: 50px!important;
}
a {
  color: var(--primary-color);
  &:hover {
    color: var(--primary-color);
  }
}
.scrollbar-container .nav-link {
  font-size: 16px!important;
  color: var(--dark-font-color)!important;
}
/* Notifications Count */
.notifications-count {
  background-color: var(--primary-color);
  border-radius: 4px;
  font-size: 12px;
  padding: 0 2px;
  min-width: 17px;
  display: inline-block;
  text-align: center;
  color: #fff;
  opacity: 0.3;
  font-family: SemiBoldFont, serif;
}
/* End Notifications Count */

.hover:hover {
  background-color: lightgray;
}


/* Datepicker */
.react-datepicker__input-container {
  font-size: 14px;
}
.full-width-datepicker {
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
}
.react-datepicker-wrapper {
  input {
    border: 1px solid #dee2e6; //important removed because alert border wasnt working in "Vebrauch erfassen" Modal
    box-shadow: none!important;
    border-radius: 0.25rem;
    max-height: 46px;
    width: 100%;
    padding: 0.625rem 0.75rem;
  }
}

/* End Datepicker */
/* SVG Map */
.germany-card-body {
  text-align: center;
  font-size: 20px;
  svg{
    height: 387px;
    margin: 0 auto 15px auto;
    display: block;
  }
}
.svg-map {
  transition-duration: 0.5s;
  stroke: var(--dark-gray);
  fill: var(--light-gray);
}
.first-level-dark {
  fill: rgba(4, 17, 51, 0.2);
}
.second-level-dark {
  fill: rgba(4, 17, 51, 0.4);
}
.third-level-dark {
  fill: rgba(4, 17, 51, 0.6);
}
.fourth-level-dark {
  fill: rgba(4, 17, 51, 0.8);
}
.fifth-level-dark {
  fill: rgba(4, 17, 51, 1);
}
/* End SVG Map */
/* Fonts */
.font-weight-thin {
  font-weight: 300;
}
/* PAGES */
/* Pricestrategy */
.attribute-fields .expansion-panel:nth-last-child(2) .expansion-panel-header {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.attribute-fields .expansion-panel:first-child .expansion-panel-header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.add-criteria-btn {
  margin-top: 25px;
}
.pricestrategy-info-icon-wrapper {
  font-size: 20px;
  position: absolute;
  right: -30px;
  top: -8px;
}
/* End Pricestrategy */
/* CompanyData */
.company-data-wrapper textarea {
  min-height: 105px!important;
}
.font-size-20 {
  font-size: 20px!important;
}
/* End CompanyData */
/* IntegrationsList */
.disabled-integration {
  background-color: #e9ecef !important;
  border-radius: 8px!important;
}
.integration-logo-wrapper {
  img{
    width: 250px;
  }
}
.add-integration {
  cursor: pointer;
  font-size: 28px;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.integrations {
  h4 {
    font-size: 18px;
  }
}
.close-integrations {
  font-size: 28px;
  cursor: pointer;
}
/* End IntegrationsList */
/* IntegrationTable */
.integration-table-logo {
  width: 125px;
}
/* End IntegrationTable */
/* Page Content Fade Animation */
.fade-out {
  opacity: 0;
  transition-duration: 0.5s;
}
.fade-in {
  opacity: 1!important;
  transition-duration: 0.5s;
}
.wait-for-fade {
  opacity: 0;
};
/* End Page Content Fade Animation */
/* Contracts_tmp */
// Table Header Buttons responisve
@media screen and (max-width: 600px){
  .break-width-600.table-header-buttons {
    .responsive-button-wrapper {
      display: block;
      button, .dropdown-button-wrapper, .dropdown-options {
        width: 100% !important;
      }
      button {
        margin-bottom: 15px !important;
      }
    }

    .dataTables_filter {
      width: 100%;
      label {
        width: 100%;
        input {
          margin-left: 0;
          width: 100%;
        }
      }
      .search-label {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 700px){
  .break-width-700.table-header-buttons {
    .responsive-button-wrapper {
      display: block;
      button, .dropdown-button-wrapper, .dropdown-options {
        width: 100% !important;
      }
      button {
        margin-bottom: 15px !important;
      }
    }

    .dataTables_filter {
      width: 100%;
      label {
        width: 100%;
        input {
          margin-left: 0;
          width: 100%;
        }
      }
      .search-label {
        display: none;
      }
    }
  }
}
.dataTables_filter {
  padding-right: 0;
  float: inherit;
  label {
    margin-bottom: 0;
  }
}
.tender-btn {
  padding: 0!important;
  .tender-btn-hitbox {
    padding: 7px 30px;
  }
}
@media screen and (max-width: 845px) and (min-width: 400px){
  .tender-btn {
    float: left;
  }
  .dropdown-button-wrapper {
    //width: 47%;
  }
  .eless-btn {
    //width: 100%; //todo auf der ganzen Seite prüfen
    //margin-right: 0!important;
  }
}
@media screen and (max-width: 845px) and (min-width: 768px){
  .custom-table-actions {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 581px) {
  .tender-btn {
    font-size: 13px!important;
    padding-left: 10px;
  }
}
.custom-table {
  h2 {
    margin-bottom: 0;
  }
  td {
    line-height: 1.9;
  }
  .custom-table-row {
    &:hover {
      background-color:#f6f9fc;
    }
  }
}
.react-bootstrap-table table {
  table-layout: auto;
}
/* End Contracts_tmp */
/* Single Contract */
.chat-wrapper {
  margin: 15px 20px;
}
.chat-actions {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
}
.chat-actions .logo-wrapper{
  border-radius: 30px;
}
.avatar {
  height: inherit;
}
.avatar-lg img{
  height: 56px;
  width: 56px;
}
.avatar-md img{
  height: 46px;
  width: 46px;
}
.avatar-sm img{
  height: 36px;
  width: 36px;
}
.timeline .avatar.rounded-circle img {
  border: 1px solid #e9ecef;
}
.rounded-circle, .avatar.rounded-circle img {
  background-color: #fff;
  max-width: inherit!important;
}
.timeline-block {
  font-size: 15px!important;
  &:last-child {
    margin-bottom: 2em;
  }
  &.selected {
    background-color: red;
  }
}




.timeline-upload {
  position: absolute;
  left: 0;
  width: 150px;
  height: 43px;
  opacity: 0;
  cursor: pointer;
}
.timeline-content {
  max-width: inherit!important;
  margin-left: 50px;
  padding: 20px 25px;
  border-radius: 10px;
}
.timeline-attachment {
  margin-top: 8px;
  h5 {
    margin-bottom: 0;
  }
  .attachments {
    font-size: 0.8125rem;
    text-decoration: underline;
    a {
      margin-right: 4px;
      cursor: pointer;
    }
    a:not(:first-child) {
      margin-left: 4px;

    }
  }
}
@media screen and (max-width: 617px){
  .chat-actions {
    .avatar, .message-input {
      margin-bottom: 15px;
    }
  }
  .responsive-line-break {
    width: 100%;
  }
  .chat-actions {
    .flex-fill {
      margin-right: 0!important;
    }
    .logo-wrapper {
      margin-bottom: 15px;
    }
    .file-upload-wrapper {
      width: 48%;
      .upload-button {
        width: 100%;
        label {
          width: 100%;
        }
      }
    }
    button {
      width: 48%;
      .btn-secondary {
        width: 100%;
      }
    }
  }
}
//.chat-actions {
//  .buttons-wrapper {
//    display: block;
//  }
//}
.uploaded-docs {
  position: absolute;
  font-size: 24px;
  right: 0;
  top: 10px;
  i.ni-single-copy-04 {
    margin-right: 10px;
    cursor: pointer;
  }
  i.ni-fat-remove {
    position: absolute;
    top: -5px;
    background-color: #e63647;
    border-radius: 15px;
    font-size: 20px;
    margin-left: -12px;
    display: none;
  }
}
.ni-single-copy-04:hover .ni-fat-remove {
  display: inline-block!important;
}
.contractdocuments-rows {
  padding-left: 20px;
  padding-right: 20px;
  .custom-border-bottom {
    margin-bottom: 0!important;
  }
  .row.file-row {
    padding-top: 20px;
    &:not(.has-changes){
      padding-bottom: 20px;
    }
  }
}
@media screen and (max-width: 715px) and (min-width: 467px) {
  .contractdocuments-rows {
    .file-row {
      .button-wrapper {
        margin-bottom: 15px;
      }
    }
  }
}
@media screen and (max-width: 466px){
  .contractdocuments-rows {
    .file-row {
      .col:first-child {
        margin-bottom: 10px;
      }
      .col {
        width: 100%!important;
        text-align: center;
        .button-wrapper {
          width: 100%;
          margin-bottom: 15px;
          div {
            text-align: center!important;
          }
        }
      }
    }
  }
}
.custom-rows-table {
  .row {
    margin-bottom:20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--light-gray);
  }
}
.dot-separator {
  vertical-align: middle;
  font-size: 4px;
  margin: 0 10px;
}
@media screen and (max-width: 767px){
  .col-max767-margin {
    margin-bottom: 15px;
  }
}
.disabled-row {
  opacity: 0.3;
}
.absolute-positioned-search {
  position: absolute;
  bottom: 0;
  right: 0;
}
.absolute-content {
  min-height: 50px;
}

/** Signle Contract Update **/
.info-section {
  margin: 25px;
  padding-bottom: 20px!important;
  font-size: 16px;
  h3 {
    text-transform: uppercase;
    font-size: 20px;
    color: var(--dark-gray);
  }
  h4 {
    font-size: 16px;
    padding: 0;
    margin-bottom: 5px;
    color: var(--dark-font-color);
    text-transform: none;
    &.two-lines {
      margin-bottom: 29px;
    }
  }
  .left-info-col {
    //margin-right: 15px;
    //max-width: 200px;
  }
  .right-info-col {
    flex: 0 1 auto;
    span {
      margin-bottom: 5px;
      display: inline-block;
    }
  }
}
/** End Signle Contract Update **/
.contract-header, .consumption-point-header {
  .buttons-wrapper {
    text-align: right;
  }
}
@media screen and (max-width: 702px) {
  .contract-header {
    .column:first-child {
      margin-bottom: 15px!important;
    }
    .column {
      width: 100%;
      max-width: inherit!important;
      flex: inherit;
      text-align: center;
    }
  }
}
@media screen and (max-width: 767px) {
  .info-section {
    h4 {
      margin-bottom: 0;
    }
    .value {
      margin-bottom: 10px;
      .changes-detected {
        margin-left: 30px;
      }
    }
  }
}
@media screen and (min-width: 576px){
  .buttons-wrapper {
    .d-responsive {
      display: inline-block;
    }
  }
}
@media screen and (max-width: 575px){
  .contract-header, .consumption-point-header {
    .list-middot > li {
      display: block;
      text-align: center;
      &:not(:last-child){
        margin-bottom: 10px;
      }
      &:after {
        content: ''!important;
        margin: 0!important;
      }
    }
    .buttons-wrapper {
      text-align: center;
      .d-responsive {
        display: block;
        margin: 0 auto!important;
        &:first-child {
          margin: 0 auto 15px auto!important;
        }
      }
    }
  }
}
@media screen and (max-width: 325px) {
  .chat-actions {
    .logo-wrapper {
      display: none;
    }
  }
}
/* End Single Contract */
/* Dashboard */
/* HeaderInfoCard */
.info-card {
  h2 {
    font-family: SemiBoldFont, serif;
  }
  .current-count {
    color: var(--secondary-color);
    font-family: BoldFont, serif;
  }
  .icon-wrapper {
    display: inline-block;
    background-color: var(--light-gray);
    padding: 10px 10px 5px;
    border-radius: 25px;
    i {
      font-size: 36px;
    }
  }

  .growth-info {
    color: var(--bright-font-color);
    b {
      &.plus {
        color: var(--success-color)!important;
      }
      &.minus {
        color: var(--error-color)!important;
      }
    }
  }
}
.info-card-title {
  font-size: 20px;
}
.current-count {
  font-size: 24px;
  color: var(--secondary-color);
  font-family: NunitoxBold, sans-serif;
  line-height: 1;
}
.growth {
  &.minus {

  }
  &.plus {

  }
}
/* End HeaderInfoCard */
.dashboard-line-chart {
  height: 500px;
}
@media screen and (min-width: 992px){
  .custom-ml {
    margin-left: 1.05rem;
  }
}
.dashboard-header {
  .form-group {
    margin-bottom: 0!important;
    select {
      min-width: 200px;
    }
  }
}
.myline:after {
  display: inline-block;
  height: 100%;
  width: 100%;
  background-size: 400% 400%;
  animation: slide 1.2s ease-in-out infinite;
  background: linear-gradient(-90deg, #C1C1C1 0%, #F8F8F8 50%, #C1C1C1 100%);
}

@keyframes slide {
  0% {transform:translateX(-200%);}
  100% {transform:translateX(100%);}
}
/* End Dashboard*/
/* Charts */
.custom-legend {
  padding: 15px 0 15px 70px;
  font-size: 12px;
  color: #8898AA;
  .legend-color {
    width: 45px;
    height: 15px;
    margin-right: 10px;
  }
}
.impressions {
  display: inline-block;
  &.legend-color{
    background-color: rgba(4, 17, 51, 1);
  }
}
.completions {
  display: inline-block;
  &.legend-color {
    background-color: rgba(254, 130, 30, 1);
  }
}
/* End Charts */
/* Guidelines */
.guidelines-body {
  font-size: 14px;
}
/* END Guidelines */
/* Tariffs Table Page */
.tariffs-table-wrapper{
  @media screen and (min-width: 846px){
    .dropdown-button-wrapper {
      margin-right: 10px;
    }
  }
}
/* End Tariffs Table Page */
/* COMPONENTS */
/* Accept Changes Footer */
.accept-changes-footer {
  padding: 20px 15px;
  background-color: rgba(253,214,142,0.59);
  border-radius: 10px;
}
.changes-detected {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  min-height: 24px;
  width: auto;
  &:before {
    content: '!';
    background-color: rgba(255, 180, 48, 0.59);
    color: #fff;
    position: absolute;
    left: -28px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    font-weight: bold;
    text-align: center;
    font-family: 'Open Sans', serif;
  }
  &:after {
    content: attr(data-tooltip);
    position: absolute;
    top: -8px;
    left: 0;
    background: #ffcb66;
    padding: 5px 15px;
    color: black;
    -webkit-border-radius: 10px;
    -moz-border-radius   : 10px;
    border-radius        : 10px;
    white-space: nowrap;
    opacity: 0;
    /* At time of this creation, only Fx4 doing pseduo transitions */
    -webkit-transition: all 0.2s ease;
    -moz-transition   : all 0.2s ease;
  }
  &:hover:after {
    opacity: 1;
  }
}
.changes-detected-download {
  background-color: rgba(255, 180, 48, 0.59);
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  font-weight: bold;
  text-align: center;
  font-family: 'Open Sans', serif;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}
.changeable-data-download {
  position: relative;
  .changes-detected-download {
    position: absolute;
    left: -28px;
  }
}
.warning-alert-icon {
  position: relative;
  text-decoration: none;
  min-height: 24px;
  width: auto;
  padding-left: 5px;
  &:before {
    content: '!';
    background-color: rgba(255, 180, 48, 0.59);
    color: #fff;
    position: absolute;
    left: -28px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    font-weight: bold;
    text-align: center;
    font-family: 'Open Sans', serif;
    margin-top: 4px;
  }
}
.padding-for-alert-icon {
  padding-left: 35px;
}
@media screen and (min-width: 768px){
  .accept-changes-footer {
    .buttons-wrapper {
      text-align: right;
    }
  }
}
@media screen and (max-width: 767px){
  .accept-changes-footer {
    .text-wrapper {
      margin-bottom: 25px;
      line-height: 1;
    }
    .buttons-wrapper {
      text-align: center;
      .eless-btn {
        margin-bottom: 10px;
        width: 100%;
        margin-right: 0!important;
      }
    }
  }
}
.edit-offer {
  float: right;
  right: 10px;
}
/* End Accept Changes Footer */
/* Input Slider */
.noUi-connect {
  background: var(--secondary-color)!important;
}
.noUi-handle {
  background-color: var(--secondary-color)!important;
  &.noUi-active {
    box-shadow: 0 0 0 2px var(--secondary-color)!important;
  }
}
.range-slider-value {
  padding-bottom: 7px;
  border-radius: 6px;
  width: 110px;
  display: block;
}
.input-slider {
  margin-bottom: 10px;
  z-index: 0;
}
/* End Input Slider */
/* file upload button */
.sm {
  .upload-button label {
    height: 31px;
    padding: 0 30px;
    line-height: 2;
  }
}
/* End file upload button */
/* Return Button */
.ni-curved-next {
  transform: rotate(180deg);
  font-size: 14px;
  margin-right: 4px;
}
/* End Return Button */
/* Settings Wheel Button */
.settings-wheel-btn {
  border-radius: 4px;
  display: inline-block;
  border: 1px solid var(--dark-font-color);
  padding: 2px 6.5px 2px 6.5px;
  cursor: pointer;
  transition-duration: 0.6s;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  transform: translateY(1px);
  &:hover {
    background-color: var(--dark-font-color);
    color: #fff;
    transition-duration: 0.3s;
  }
}
/* End Settings Wheel Button */
/* List Dotted */
.list-middot {
  list-style: none;
  padding: 0;
}

.list-middot > li {
  display: inline;
}
.list-middot > li:not(:last-child)::after {
  content: "\00b7";
  margin: 0 15px;
  font-size: 20px;
  font-weight: bold;
}
/* End List Dotted */
/* Info Box */
.info-box {
  position: absolute;
  border-radius: 5px;
  background: var(--light-gray);
  z-index: 1;
  max-width: 250px;
  font-size: 12px;
  color: var(--dark-font-color);
}
.info-box-header {
  padding: 10px 20px;
  border-bottom: 1px solid #fff;
  text-align: center;
}
.info-box-body {
  padding: 10px 20px;
}
.info-box-body-item {
  margin-bottom: 4px;
}
.info-box-separator {
  border-bottom: 1.5px solid var(--dark-font-color);
}
@media screen and (min-width: 941px) {
  .payment-method-info {
    left: 110px;
    right: -150px;
    top: 0;
  }
  .handling-fee-info {
    left: 130px;
    right: -150px;
    top: 0;
  }
}
/* End Info Box */
/* NavTabs */
.nav-tabs {
  border-bottom: none;
  font-size: 20px;
  background-color: #F6F9FC;
  .nav-link {
    padding: 0.75rem;
    border-radius: 0;
    border-bottom: 1px solid var(--light-gray);
    h2 {
      margin-bottom: 0;
    }
    .active {
      transition-duration: 0.5s;
      background-color: #fff;
    }
    &:hover:not(.active) {
      border-color: #E9ECEF;
    }
  }
  &.round  {
    li {
      &:first-child {
        .nav-link {
          border-top-left-radius: calc(0.375rem - 1px);
        }
      }
      &:last-child {
        .nav-link {
          border-top-right-radius: calc(0.375rem - 1px);
        }
      }
    }
  }
  .nav-item {
    width: 20%;
    text-align: center;
    cursor: pointer;
    color: #b5b5b5;
    .active {
      color: var(--dark-font-color);
      font-family: BoldFont, serif!important;
      background-color: #fff!important;
      &:hover {
        color: var(--dark-font-color);
      }
    }
  }
}
.two-tabs {
  .nav-item{
    width: 50%;
  }
}
.three-tabs {
  .nav-item{
    width: 33%;
  }
}
.four-tabs {
  .nav-item{
    width: 25%;
  }
}
.five-tabs {
  .nav-item{
    width: 20%;
  }
}
@media screen and (max-width: 827px) {
  .five-tabs, .four-tabs, .three-tabs {
    .nav-item{
      width: 100%!important;
    }
  }
}
@media screen and (max-width: 500px){
  .two-tabs {
    .nav-item{
      width: 100%;
    }
  }
}
/* End NavTabs */
/* TariffCalculatedContainer */
.calculated-tariff .custom-border-bottom {
  margin-bottom: 0;
}
.tariff-header-boxes {
  position: absolute;
  top: -10px;
  right: 30px;
}
.tariff-subtitle {
  text-transform: inherit;
  font-size: 16px;
  margin-bottom: 0;
  color: var(--primary-color);
}
.tariff-subtitle-data {
  font-family: RegularFont, serif!important;
  font-size: 22px;
}
.tariff-footer {
  padding: 15px 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
}
.panel {
  height: 0;
  background: #fff;
  transition-duration: 0.5s;
  overflow: hidden;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.panel.show {
  height: auto;
}
.tariff-price-details-row {
  padding: 10px 30px
}
.tariff-details-row-title{
  font-family: SemiBoldFont, serif!important;
}

.price-detail-btn {
  border: 1px solid var(--dark-gray);
  box-shadow: none;
  color: var(--dark-gray);
  background-color: #fff;
}
.price-detail-btn:focus {
  box-shadow: none;
}
.price-detail-btn:hover {
  transform: inherit;
  border: 1px solid var(--dark-gray);
  box-shadow: none;
  color: var(--dark-gray);
  background-color: #fff;
}
.price-detail-btn:disabled {
  border: 1px solid var(--dark-gray);
  color: var(--dark-gray);
}
.contract-header-detail-loading-first {
  position: absolute;
  right: 125px;
  top: -20px;
  border-radius: 5px;
}
.contract-header-detail-loading-second {
  position: absolute;
  right: 35px;
  top: -20px;
  border-radius: 5px;
}
.digital-contract {
  min-width: 146px;
}
.e-signature-badge {
  background-color: #fff;
  padding: 3px 15px;
  border-radius: 5px;
  border: 1px solid #E9ECEF;
}
.electricity-type {
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  &.green {
    background-color: #1AAE6F;
  }
  &.grey {
    background-color: var(--dark-gray);
  }
}
/* End TariffCalculatedContainer */
/* Agb Modal */
.agb-modal {
  max-width: 700px;
  text-align: justify;
  .agb-text h2{
    margin-bottom: 0;
  }
}
/* End Agb Modal */
/* Crop Img Modal */
.cropimg-button-wrapper {
  text-align: center;
  margin-top: 35px;
}
.file-upload-button {
  line-height: inherit!important;
}
/* End Crop Img Modal */
/* Save Form Data */
.save-form-data-wrapper {
  z-index: 1500;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  transition-duration: 0.5s;
  &.display {
    height: 61px;
    padding: 15px 45px;
  }
  &.hide {
    height: 0;
    padding: 0 45px;
  }
}
@media screen and (max-width: 829px) and (min-width: 741px) {
  .save-form-data-wrapper {
    &.display {
      height: 98px;
    }
  }
}
@media screen and (max-width: 740px) and (min-width: 445px){
  .save-form-data-wrapper {
    &.display {
      height: 120px;
    }
  }
}
@media screen and (max-width: 444px) and (min-width: 400px) {
  .save-form-data-wrapper {
    &.display {
      height: 140px;
    }
  }
}
@media screen and (max-width: 399px) {
  .save-form-data-wrapper {
    &.display {
      height: 180px;
      .primary-btn {
        margin-bottom: 10px;
      }
      .btn-secondary {
        margin-left: 0;
      }
    }
  }
}
/* End Save Form Data */
/* Admin Navbar */
.notification-icon {
  font-size: 40px;
  padding-top: 10px;
}
.notifications-toggler .fas {
  font-size: 22px;
}
/* End Admin Navbar */
/* Percent Value Input */
.percent-value-wrapper {
  input {
    text-align: right;
    padding-right: 30px;
  }
  input:disabled + span {
    color: var(--disabled-color)!important;
  }
  span {
    position: absolute;
    top: 13px;
    right: 12px;
    font-size: 14px;
  }
};
/* End Percent Value Input */
/* More Options */
.more-options-wrapper {
  .more-options {
    font-size: 24px;
    color: var(--dark-gray);
    cursor: pointer;
    padding: 0 15px;
  }
  .dropdown-options{
    border: 1px solid var(--primary-color);
    padding: 2px 15px;
    cursor: pointer;
    transition-duration: 0.5s;
    border-radius: 5px;
    div {
      margin: 0 auto;
    }
    &.left {
      left: -85px;
      top:-10px;
    }
    &:hover {
      background-color: var(--primary-color);
      color: #fff;
      transition-duration: 0.5s;
    }
  }

  .fade-enter {
    opacity: 0;
  }


  .fade-enter.fade-enter-active {
    opacity: 1;
    transition-duration: 0.5s;
  }

  .fade-leave {
    opacity: 0;
  }
}
/* End More Options */
/* InputHandler */
.mt-checkbox-group {
  margin-top: 26px;
}
.mt-input-group {
  margin-top: 32px;
}
/* End InputHandler */
/* Checkbox */
.custom-checkbox {
  .custom-control-input:disabled:checked ~ .custom-control-label::before,
  .custom-control-input:disabled:checked ~ .custom-control-label::after {
    background-color: #b2b0b0 !important;
    border: 1px solid #8898AA!important;
    border-radius: 4px;
  }
  .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: #e9ecef!important;
    border: 1px solid #dee2e6!important;
    border-radius: 4px;
  }
}
.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color)!important;
    background-color: var(--primary-color)!important;
  }
}
/* End Checkbox */
/* RadiosBoolean */
.custom-control-input:active ~ .custom-control-label::before {
  background-color: var(--dark-font-color);
  border-color: var(--dark-font-color);
}
.max-form-width {
  max-width: 420px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--dark-font-color);
  border-color: var(--dark-font-color);
  &:active {
    background-color: var(--dark-font-color)!important;
  }
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #bebebe !important;
  border: 1px solid #8898AA!important;
}
.custom-radio {
  .custom-control-label {
    padding-top: 1.5px;
  }
}
.custom-checkbox {
  .custom-control-label {
    margin-bottom: 10px;
  }
}
/* End RadiosBoolean */
/* Select */
select {
  font-family: NunitoRegular, sans-serif!important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.custom-select {
  color: var(--primary-color);
}
.custom-select-arrow:after {
  font-family: "Font Awesome 5 Free", serif;
  position: absolute;
  right: 15px;
  top: 16px;
  font-weight: 900;
  content: "\F078";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: var(--grey-font-color);
  font-size: 16px;
  pointer-events: none;
}
/* End Select */
/* Badges */
.smaller-text-style {
  font-size: 11px!important;
  padding: 4px!important;
}
.badge {
  &.badge-dot {
    font-size: 16px;
    i  {
      margin-bottom: 1px;
    }
  }
}
.badge-pill {
  letter-spacing: 1.5px;
  font-size: 12px;
  padding: 4px 15px 4px 15px;
  font-family: SemiBoldFont, serif;
  width: 160px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
}
.badge-orange {
  background-color: #FFDABC;
  color: #FE821E;
}
.badge-green {
  background-color: #B0EED3;
  color: #1AAE6F;
}
.badge-red {
  background-color: #FDD1DA;
  color: #F80031;
}
.badge-gray {
  background-color: #D5D5D5;
  color: #8F7676;
}
.badge-blue {
  background-color: #aed9ff;
  color: #357df8;
}

.message-background {
  background-color: #fafafa;
  &.message-sent {
    background-color: #04113314!important; //background color for timeline messages has to have less opacity
    &:before {
      opacity: 0.1;
    }
  }
  &.message-selected {
    background-color: rgba(252, 253, 101, 0.35) !important; //background color for timeline messages has to have less opacity
    &:before {
      opacity: 0.1;
    }
  }
}
/* End Badges */
/* BlackLink Button */
.black-link-button {
  text-decoration: underline;
  color: var(--primary-color);
  cursor: pointer;
}
/* End BlackLink Button */
/* Grey Button */
.grey-btn {
  background-color: #F6F9FC !important;
}
/* End Grey Button */
/* Tender Button */
.alert-tender-logo {
  width: 250px;
  padding: 60px 0;
}
.tender-cancel-btn {
  width: 308px;
}
.tender-btn {
  border: 1px solid #A7BC39;
  background-color: #133946;
  color: #fff;
  font-weight: normal;
  width: 256px;
  .tender365 {
    color: #7fa5b2;
    font-weight: bold;
  }
  &:disabled {
    border: 1px solid #A7BC39;
    background-color: #133946;
    color: #fff;
    &:hover {
      transform: inherit;
      box-shadow: none;
    }
  }
  &:hover{
    border: 1px solid #A7BC39;
    background-color: #133946;
    color: #fff;
  }
  &:active {
    border: 1px solid #A7BC39!important;
    background-color: #133946!important;
    color: #fff!important;
    font-size: 0.850rem;
  }
}
.tender-alert {
  padding: 20px;
  min-height: 348px;
  &.hide-data {
    .text, .eless-btn, h2{
      transition-duration: 0.5s;
      opacity: 0;
    }
  }
  &.show-loading {
    .modal-loading {
      opacity: 1!important;
    }
  }
  .eless-btn {
    min-width: 160px!important;
    padding-top: 8px;
    padding-bottom: 8px;
    height: inherit;
  }
  h2 {
    font-size: 24px;
  }
  .text {
    font-size: 14px;
    color: #041133;
  }
  .text-muted {
    line-height: 1.2!important;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 479px) and (min-width: 400px){
  .tender-btn {
    .tender-btn-hitbox{
      padding: 0 5px!important;
      font-size: 11px!important;
    }
  }
}
@media screen and (max-width: 691px) {
  .tender-btn {
    margin-right: 0!important;
  }
}
@media screen and (max-width: 399px) {
  .tender-wrapper {
    width: 100%;
    margin-bottom: 10px;
    .tender-btn {
      width: 100%;
    }
  }
}
.tender-wrapper {
  margin-right: 10px;
}
@media screen and (max-width: 845px) and (min-width: 400px) {
  .tender-wrapper {
    width: 51%;
    .tender-btn {
      width: 100%;
    }
  }
}
/* End Tender Button */
/* Export Button */
.dopdown-button {
  .active {
    background-color: var(--secondary-color);
  }
}
/* End Export Button */
/* Table Style */
.table td {
  font-size: 16px!important;
}
.table th {
  vertical-align: inherit!important;
  font-family: SemiBoldFont, Serif;
}
.order-4:before, .order-4:after, .caret-4-desc:after, .caret-4-desc:before, .caret-4-asc:before, .caret-4-asc:after  {
  top: 18px!important;
}
.row-toggler {
  cursor: pointer;
}
.empty-table-text{
  color: var(--dark-gray);
};
.react-bootstrap-table-pagination .dataTables_length label {
  margin-bottom: 1.5rem;
  white-space: inherit;
}
@media screen and (min-width: 768px) {
  .table-title-wrapper {
    padding-left: 5px!important;
  }
}
.react-bootstrap-table {
  margin-bottom: 15px;
}
.react-bootstrap-table .table {
  margin-bottom: 0;
}
.custom-table-link{
  color: var(--dark-font-color);
}
.custom-table-link:hover{
  text-decoration: none;
  cursor: pointer;
  color: var(--secondary-color);

}
.linked-col {
  cursor: pointer;
}
.selection-input-4 {
  cursor: pointer;
}
.custom-size-per-page {
  display: inline-block;
  width: inherit;
}
.custom-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.pagination {
  justify-content: flex-end;
}
@media screen and (max-width: 691px){
  .pagination {
    justify-content: center!important;
  }
}
@media screen and (max-width: 575px){
  .react-bootstrap-table-pagination-list{
    display: none;
  }
}
/* SizePerPage Select */
.size-per-page-select {
  height: 24px;
  line-height: 1;
  width: 42px;
}
/* End SizePerPage Select */
/* End Table Style */
/* Secondary Button */
.btn-outline-eless {
  border: 1px solid var(--secondary-color);
  background-color: #ffffff;
  color: var(--secondary-color);
  transform: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
  &:hover:not(:disabled) {
    background-color: #ffffff;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
  }
}
.arrow-before {
  &:before {
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    content: "\f060";
    margin-right: auto;
    padding-right: 10px;
  }
}
.arrow-after {
  &:after {
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    content: "\f061";
    margin-left: auto;
    padding-left: 10px;
  }
}
.full-width-button {
  width: 100%;
  height: 46px;
}
/* End Secondary Button */
/* Dropdown Button */
.dropdown-button-wrapper {
  position: relative;
  display: inline-block;
  .dropdown-button {
    margin-right: 0;
    color: var(--secondary-color);
    transition-delay: .4s;
    transform: none;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)!important;
    &.active {
      background-color: var(--secondary-color)!important;
      color: var(--light-font-color)!important;
      border-color: var(--secondary-color)!important;
    }
    &.primary-btn {
      color:  var(--secondary-color);
      &:hover {
        color: var(--secondary-color)!important;
        &:active {
          color: var(--light-font-color)!important;
        }
        &.active {
          color: var(--light-font-color)!important;
        }
      }
    }
    &:active {
      background-color: var(--secondary-color)!important;
      color: var(--light-font-color)!important;
      border-color: var(--secondary-color)!important;
      transition-delay: 0s;
    }
    &:hover {
      border-color: var(--secondary-color);
      color: var(--secondary-color);
    }
    &:after {
      display: inline-block;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: 'Font Awesome 5 Free';
      font-weight: 700;
      content: "\F107";
      margin-left: auto;
      padding-left: 10px;
    }
    &:disabled {
      pointer-events: none!important;
      border-color: var(--secondary-color);
    }
  }
  .dropdown-options {
    left: 0;
    transition: all .5s ease-in-out;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    font-size: 0.875rem;
    position: absolute;
    z-index: 10;
    background-color: #fff;
    width: 143.5px; //changed from 142.5 because ContractNavsPage -> Consumptionpoints Table Dropdown Button 142.5 is too short
    font-weight: bold;
    top: 29px;
    transition-duration: 0.5s;
    overflow-y: hidden;
    div {
      text-align: center;
      padding: 5px 0 5px 0;
      cursor: pointer;
      margin: 0!important;
      width: 100%;
      &:hover {
        color: var(--light-font-color);
        background-color: var(--secondary-color);
      }
    }
  }
}
@media screen and (max-width: 399px) {
  .dropdown-button-wrapper {
    .dropdown-button {
      width: 100%!important;
    }
  }
}
/* Dropdown Animation */
.dropdown-button-wrapper {
  .fade-enter {
    height: 0;
  }

  .fade-enter.fade-enter-active.four-options {
    height: 128px;
  }

  .fade-leave.four-options {
    height: 128px;
  }

  .fade-enter.fade-enter-active.three-options {
    height: 96px;
  }

  .fade-leave.three-options {
    height: 96px;
  }

  .fade-enter.fade-enter-active {
    height: 64px;
  }

  .fade-leave {
    height: 64px;
  }

  .fade-enter.fade-enter-active.one-option {
    height: 32px;
  }

  .fade-leave.one-option {
    height: 32px;
  }

  .fade-enter.fade-enter-active.two-options {
    height: 64px;
  }

  .fade-leave.two-options {
    height: 64px;
  }

  .fade-leave.fade-leave-active { //Dieser Style muss als letztes kommen sonst buggt die Animation beim Schliessen
    height: 0;
  }
}
/* End Dropdown Button */
/* Modal loading Animation */
.modal-loading {
  margin-top: 30px;
  opacity: 0;
  transition-duration: 0.5s;
  i {
    font-size: 56px;
    display: block;
    margin-top: 20px;
  }
}
/* End Modal loading Animation */
/* Button loading Animation */
.button-loading {
  display: none;
  position: absolute;
  right: 20px;
  top: 4px; //Pricestrategy submitbutton needs 6px
  font-size: 24px;
}
/* End Button loading Animation */
/* Navabr User Logo Loading Animation */
.loading-user-logo span span {
  vertical-align: middle;
  border-style: none;
}
/* End Navabr User Logo Loading Animation */
/* Sidebar Loading Animation */
.sub-nav-link-padding {
  padding-left: 0.7rem;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
}
.collapse-loading:after {
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  content: "\F105";
  margin-left: auto;
  color: #ced4da;
  transition: all 0.15s ease;
}
.collapse-loading.expanded:after {
  transform: rotate(90deg);
}
/* End Sidebar Loading Animation */
/* Tooltip Button */
.huge-submit-hitbox {
  padding: 12px 30px;
  display: block;
}
/* End Tooltip Button */
/* Download Button */
.download-btn {
  padding-right: 45px;
  padding-left: 20px;
  position: relative;
  .download-icon {
    position: absolute;
    right: 18px;
    top: 6px;
    font-size: 18px;
  }
}
.btn-right {
  text-align: right;
}
.collapse-header {
  background-color: #F6F9FC;
  color: var(--dark-gray);
  font-size: 15px;
  letter-spacing: 0.9px;
  font-weight: bold;
  display: table;
  margin-bottom: 20px;
  .col {
    display: table-cell;
    vertical-align: middle;
  }
  .fas {
    font-size: 20px;
    color: #00C4AC;
  }
}
/* End Download Button */
/* Download Component */
.download-icon-btn {
  border: 1px solid var(--light-gray);
  color: var(--bright-font-color);
  border-radius: 4px;
  display: inline-block;
  padding: 8px 16px 4px;
  cursor: pointer;
  background: #fff;
  &.disabled {
    background: var(--light-gray);
    cursor: inherit;
  }
}
/* End Download Component */
/* GreyLink Button */
div.disabled {
  .grey-link-button {
    opacity: 0.5;
    cursor: inherit;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}
.grey-link-button {
  text-decoration: underline;
  color: var(--dark-gray);
  cursor: pointer;
}
.eless-color {
  .grey-link-button {
    color: var(--dark-font-color)!important;
    font-family: SemiBoldFont, serif;
  }
}
/* End GreyLink Button */
/* Full Page Submit Button */
.full-page-submit {
  width: 100%;
  height: 46px;
}
/* End Full Page Submit Button */
/* Submit Button */
.primary-btn {
  overflow: hidden;
  position: relative;
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: var(--light-font-color)!important;
  transition-delay: 0s!important;
  &:hover {
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color);
    color: var(--light-font-color);
  }
}
.primary-btn {
  &:disabled {
    background-color: var(--secondary-color);
    color: #ffffff;
    border: 1px solid var(--secondary-color);
    &:hover {
      box-shadow: none;
      transform: inherit;
    }
  }
}
.btn-secondary {
  &:disabled {
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    &:hover {
      box-shadow: none;
      transform: inherit;
    }
  }
}
@media screen and (min-width: 692px) {
  .mobile-submit-style button {
    margin-left: 15px;
  }
}
@media screen and (max-width: 691px) {
  .mobile-submit-style button {
    //width: 48%; //TODO Prüfen ob das weggelassen werden darf!!
    //padding: 0 10px;
  }
}
//This is CSS for the primary button inside a warning alert from argon dashboard
.btn-primary.primary-btn {
  &:hover {
    transform: inherit!important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  &:active {
    background-color: var(--secondary-color)!important;
    color: #ffffff!important;
    border: 1px solid var(--secondary-color)!important;
  }
}
/* End Submit Button
/* Dropzone */
.thin-dropzone p {
  padding: 8.5px 1rem!important;
}
.dropzone{
  display: block;
  p {
    padding: 5rem 1rem;
    border: 1px dashed var(--light-gray);
    text-align: center;
    cursor: pointer;
    &:hover {
      border-color: gray;
    }
  }
}
.dropzone-skeleton {
  margin-bottom: 1rem;
}
.uploaded-dropzone-file-wrapper {
  margin-bottom: 15px;
  .avatar-wrapper {
    img {
      color: #fff;
      background-color: #adb5bd;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      border-radius: 0.375rem;
      height: 48px;
      width: 48px;
    }
  }
}
/* End Dropzone */

/* AUTO COMPLETE GET AG SERVICE */
/* auto- complete list design (GETAG) */
.autocomplete-items {
  position: absolute;
  border: 1px solid #f3f3f3;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 77%;
  left: 0;
  right: 0;
  border-radius: 20px!important;
  background-color: #fff;
}
.font-inherit {
  font: inherit!important;
}
.autocomplete-items div:last-child {
  border-bottom: none!important;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid #f3f3f3;
}
#street:disabled, #city.disabled{
  opacity: 0.6;
}
/*when hovering an item:*/
.autocomplete-items div:first-child:hover {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.autocomplete-items div:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.autocomplete-items div:last-child:hover {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.autocomplete-items div:hover {
  background-color: #041133;
  color: white;
}
/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: #041133;
  color: white;
}

/* END AUTO COMPLETE */





/* Sidebar */
.scrollbar-inner h6 {
  font-size: 15px!important;
  letter-spacing: 0!important;
}
.navbar-vertical {
  .navbar-nav {
    padding-left: 10px;
    .nav-link > i{
      font-size: 18px;
      min-width: 32px;
      margin-top: -5px;
    }
    .nav-link[data-toggle="collapse"][aria-expanded="true"]:after {
      color: var(--primary-color);
    }
  }
}
.navbar-brand-img {
  max-height: 110px!important;
}
.navbar-horizontal .navbar-brand img {
  height: 65px!important;
}
/* End Sidebar */
/* Admin Navbar */
.notifications-toggler {
  cursor: pointer;
  .ni {
    font-size: 22px;
  }
}
.new-events-count {
  background-color: var(--secondary-color);
  position: absolute;
  line-height: 0.9;
  border-radius: 12px;
  text-align: center;
  font-weight: bold;
  &.endless {
    height: 20px;
    width: 20px;
    bottom: -6px;
    right: 4px;
    padding-top: 3px;
    span {
      font-size: 10px;
      position: absolute;
      right: 1.5px;
      top: 5px;
    }
  }
  &.big {
    height: 20px;
    width: 20px;
    bottom: -6px;
    right: 4px;
    padding-top: 3px;
    span {
      font-size: 12px;
      position: absolute;
      right: 4.5px;
      top: 5px;
    }
  }
  &.low {
    height: 20px;
    width: 20px;
    bottom: -6px;
    right: 6px;
    padding-top: 2px;
    span {
      font-size: 12px;
      position: absolute;
      right: 6.5px;
      top: 5px;
    }
  }
}
/* Admin Navbar */
/* File Upload */
.custom-file {
  height: 46px;
}
.custom-file-label::after {
  content: "Suchen"!important;
}
.delete-file {
  color: var(--dark-font-color);
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: 85px;
  top: 0;
  padding-top: 13px;
  height: 46px;
  font-size: 13px;
  z-index: 10;
}
/* End File Upload */
/* Modal */
.modal-content-end-margin {
  margin-bottom: 2.5rem;
}
.modal-text-section {
  margin-top: 35px;
  border-top: 1px solid var(--light-gray);
}
.btn-modal {
  padding: 11.5px 0!important;
  height: inherit!important;
  width: 100%;
}
.btn-danger, .btn-danger:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.btn-default, .btn-default:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.history-subject {
  font-family: SemiBoldFont, serif;
}
.swal2-icon.swal2-question {
  color: var(--primary-color);
}
.close-button {
  font-size: 28px;
}
.modal-backdrop {
  z-index: 1000!important;
}
/* End Modal */
/* Custom Alert */
.custom-alert {
  .modal-content {
    padding: 3% 10%;
    text-align: center;
    .btn-modal {
      margin-bottom: 12px!important;
    }
  }
}
.custom-alert-title {
  font-size: 28px;
  font-family: NunitoRegular, sans-serif;
  margin-bottom: 15px;
}
.swal2-icon {
  border-color: #B3B7C1!important;
  color: var(var(--primary-color))!important;
  .swal2-success-ring {
    border-color: #B3B7C1!important;
  }
  .swal2-success-line-long {
    background-color:  var(var(--primary-color))!important;
  }
  .swal2-success-line-tip {
    background-color: var(var(--primary-color))!important;
  }
}
.swal2-icon.swal2-warning {
  border-color: var(var(--secondary-color))!important;
  color: var(var(--secondary-color))!important;
}
.sweet-alert h2 {
  font-size: 24px;
}
.lead {
  font-size: 1.1rem!important;
}
.rna-wrapper div {
  z-index: 2000!important;
  margin-left: auto;
  margin-right: auto;
}
/* End Custom Alert */
/* Create PFC Modal */
ul {
  margin-bottom: 25px;
  &.pfc-checklist {
    padding-left: 15px!important;
    padding-top: 15px;
    li {
      display: inline-block;
      padding: 10px 0;
      span {
        display: inline-block;
        max-width: 390px;
        font-family: Open Sans, sans-serif!important;
        font-size: 1rem!important;
      }
      &::before {
        color: var(--success-color)!important;
        padding-right: 20px;
      }
    }
  }
}
@media screen and (max-width: 504px) and (min-width: 406px) {
  ul {
    &.pfc-checklist {
      li {
        span {
          max-width: 291px!important;
        }
      }
    }
  }
}
@media screen and (max-width: 405px)  and (min-width: 301px) {
  ul {
    &.pfc-checklist {
      li {
        span {
          max-width: 185px!important;
        }
      }
    }
  }
}
/* End Create PFC Modal */
/* Search */
@media screen and (max-width: 767px) {
  .absolute-positioned-search {
    left: 15px;
  }
}
@media screen and (max-width: 991px) {
  .settings-wheel-btn.absolute-positioning {
    position: absolute;
    right: 15px;
    top: -45px;
  }
}
@media screen and (min-width: 846px) {
  .table-actions-wrapper {
    text-align: right;
  }
}
/* End Search */
/* Input Error */
.error {
  color: var(--error-color);
}
.argon-error-message {
  //width: 100%; //Die Fehlermeldung bei Dokumente hochladen im Auftrag war nicht sichtbar wegen dieses styles
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
  position: absolute;
}
.alert-border {
  border: 1px solid var(--error-color)!important;
}
.alert-border-child {
  .multi-select .dropdown .dropdown-heading {
    border: 1px solid var(--error-color)!important;
  }
}
/* End Input Error */
/* Footer */
#footer-main {
  width: 100%;
}
/* End Footer */

/* Progress Bar File Upload (Single Contract) */
.progress-wrapper {
  min-height: 205px;
  padding-top: 3.5rem;
}
.progress-bar {
  width: 60%;
}
/* End Progress Bar File Upload (Single Contract) */
/* Editing Header */
.editing-header-input {
  background-color: var(--primary-color);
  border: none;
  width: 100%;
  overflow-x: auto;
  color: #fff;
  display: inline-block;
  &.title-edit {
    height: 39px;
    font-weight: bold;
  }
  &.description-edit {
    height: 25px;
    font-size: 0.925rem;
  }
}
.dotted-underline {
  display: inline-block;
  background-image: linear-gradient(to right, #a3a4a4 40%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
/* End Editing Header */
/* Eless Stepper Breadcrumb */
.eless-stepper {
  text-transform: uppercase;
  padding: 10px 12px;;
  background-color: var(--background-color);
  color: var(--grey-font-color);
  //background-color: var(--light-gray);
  font-family: SemiBoldFont, serif;
  margin-left: -24px;
  margin-right: -24px;
  .active {
    color: var(--secondary-color);
  }
}
/* End Eless Stepper Breadcrumb */
/* Multistep Breadcrumb */
.MuiSvgIcon-root {
  font-size: 1.85rem!important;
}
.MuiStepLabel-labelContainer {
  position: absolute;
  top: -40px;
}
.MuiPaper-root {
  padding-top: 40px!important;
  .MuiStep-root:nth-child(1) {
    padding-left: 0!important;
    .MuiStepLabel-root {
      align-items: flex-start;
      .MuiTypography-root {
        text-align: left;
      }
    }}}
.MuiPaper-root .MuiStep-root:nth-child(2) {
  .MuiStepConnector-root {
    left: calc(-99% + 42px) !important;
    right: calc(75% + 3px) !important;
  }
  .MuiStepLabel-root {
    align-items: flex-start;
    .MuiStepLabel-iconContainer {
      margin-left: 25%;
    }
    .MuiTypography-root {
      text-align: left;
      padding-left: 17%;
    }
  }}
.MuiPaper-root .MuiStep-root:nth-child(3) {
  .MuiStepConnector-root {
    left: calc(-74% + 35px) !important;
    right: calc(26% + 37px) !important;
  }
  .MuiStepLabel-root {
    align-items: flex-end;
    .MuiStepLabel-iconContainer {
      margin-right: 25%;
    }
    .MuiTypography-root {
      text-align: right;
      padding-right: 18%;
    }
  }}
.MuiPaper-root .MuiStep-root:nth-child(4) {
  padding-right: 0!important;
  .MuiStepConnector-root {
    left: calc(-24% + 0px) !important;
    right: calc(-1% + 40px) !important;
  }
  .MuiStepLabel-root {
    align-items: flex-end;
    .MuiTypography-root {
      text-align: right;
    }
  }}
.MuiStep-horizontal {
  padding: 0;
}
.MuiStep-completed {
  .linked-label {
    cursor: pointer;
    padding-bottom: 37px;
  }
}
.MuiStepper-root {
  padding-right: 0!important;
  padding-left: 0!important;
  .MuiStepLabel-active {
    color: var(--secondary-color)!important;
  }
}

.MuiStepConnector-alternativeLabel {
  .MuiStepConnector-line {
    border-radius: 15px;
    height: 5px;
    border: 0;
    background-color: var(--light-gray);
  }
  &.MuiStepConnector-active, &.MuiStepConnector-completed {
    .MuiStepConnector-line {
      background-color: var(--secondary-color);
    }
  }
}
.MuiStepIcon-root {
  color: var(--light-gray)!important;
  .MuiStepIcon-text  {
    fill: var(--dark-gray);
  }
  &.MuiStepIcon-active .MuiStepIcon-text {
    fill: #fff;
  }
  &.MuiStepIcon-active {
    color: var(--secondary-color)!important;
  }
  &.MuiStepIcon-completed {
    color: var(--secondary-color)!important;
    cursor: pointer;
  }
}
@media screen and (max-width: 559px){
  .MuiTypography-root {
    display: none!important;
  }
}
@media screen and (max-width: 744px){
  .test-and-publish {
    display: none;
  }
}
/* End Multistep Breadcrumb */
/* LoadingAnimation 2 */
.loader{
  border-radius:4px;
  transition:opacity 400ms;
}

@keyframes pulse{
  0%{
    height:0%;
  }
  20%{
    background: var(--dark-gray);
    height:100%;
  }
  50%{
    background: var(--primary-color);
  }
  80%{
    background: var(--dark-gray);
    height:100%;
  }
  100%{
    height:0%;
  }
}

.loader > ul.loader-ul{
  list-style:none;
}

.loader li{
  width:4px;
  height:30px;
  border-radius:2px;
  position:absolute;
  left:50%;
  top: 40px;
  margin-left:-2px;
  transform-origin:2px 50px;
}

.loader li div{
  border-radius:2px;
  width:4px;
  background:rgba(255,255,255,0.5);
  position:absolute;
  bottom:0px;
  left:0px;
  animation:pulse 6s normal infinite;
}

ul.loader-ul>li:nth-of-type(1){
  transform:rotate(0deg);
  box-shadow:0 1px 0 rgba(255,255,255,0.1);
}

ul.loader-ul>li:nth-of-type(1)>div{
  animation-delay:100ms;
}

ul.loader-ul>li:nth-of-type(2){
  transform:rotate(30deg);
}

ul.loader-ul>li:nth-of-type(2)>div{
  animation-delay:200ms;
}


ul.loader-ul>li:nth-of-type(3){
  transform:rotate(60deg);
}

ul.loader-ul>li:nth-of-type(3)>div{
  animation-delay:300ms;
}


ul.loader-ul>li:nth-of-type(4){
  transform:rotate(90deg);
}

ul.loader-ul>li:nth-of-type(4)>div{
  animation-delay:400ms;
}


ul.loader-ul>li:nth-of-type(5){
  transform:rotate(120deg);
}

ul.loader-ul>li:nth-of-type(5)>div{
  animation-delay:500ms;
}


ul.loader-ul>li:nth-of-type(6){
  transform:rotate(150deg);
}

ul.loader-ul>li:nth-of-type(6)>div{
  animation-delay:600ms;
}


ul.loader-ul>li:nth-of-type(7){
  transform:rotate(180deg);
}

ul.loader-ul>li:nth-of-type(7)>div{
  animation-delay:700ms;
}


ul.loader-ul>li:nth-of-type(8){
  transform:rotate(210deg);
}

ul.loader-ul>li:nth-of-type(8)>div{
  animation-delay:800ms;
}


ul.loader-ul>li:nth-of-type(9){
  transform:rotate(240deg);
}
ul.loader-ul>li:nth-of-type(9)>div{
  animation-delay:900ms;
}


ul.loader-ul>li:nth-of-type(10){
  transform:rotate(270deg);
}
ul.loader-ul>li:nth-of-type(10)>div{
  animation-delay:1000ms;
}



ul.loader-ul>li:nth-of-type(11){
  transform:rotate(300deg);
}

ul.loader-ul>li:nth-of-type(11)>div{
  animation-delay:1100ms;
}


ul.loader-ul>li:nth-of-type(12){
  transform:rotate(330deg);
}

ul.loader-ul>li:nth-of-type(12)>div{
  animation-delay:1200ms;
}
.loader{
  width:320px;
  height: 100px;
}
/* End LoadingAnimation 2 */