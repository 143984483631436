.abstract-dropdown {
  &.primary-dropdown-colors {
    .options {
      color: var(--primary-color);
      border-color: var(--primary-color);
      div:hover {
        background-color: var(--primary-color);
        color: #fff;
      }
    }
    button {
      background-color: #fff;
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
    &.active {
      button {
        background-color: var(--primary-color);
        color: #fff;
      }
    }
  }
  &.secondary-dropdown-colors {
    .options {
      color: var(--secondary-color);
      border-color: var(--secondary-color);
      div:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }
    }
    button {
      background-color: #fff;
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
    &.active {
      button {
        background-color: var(--secondary-color);
        color: #fff;
      }
    }
  }
}

.upload-field-dropdown-options {
  left: -54px!important;
}

.dropdown-toggler i {
  line-height: 1.5;
}

.abstract-dropdown {
  display: inline-block;
  .options {
    z-index: 10;
    position: absolute;
    white-space: nowrap;
    text-align: left;
    border: 1px solid;
    background-color: #fff;
    font-size: 15px;
    font-family: SemiBoldFont, serif;
    &.standard-width {
      width: 144px;
    }
    div {
      padding: 3px 10px;
      cursor: pointer;
    }
  };
  &.disabled {
    cursor: default;
    pointer-events: none;
  }
  &.active {
    .dropdown-toggler button {
      transform: none!important;
    }
  }
}